<template>
    <div
        class="component-navbar"
        :class="navContainerClasses"
    >
        <div class="nav-container container">
            <nav class="nav">
                <div class="left">
                    <a
                        class="brand"
                        href="/"
                        aria-label="Aven"
                    >
                        <img
                            src="../assets/images/components/navbar/aven.svg"
                            class="logo"
                            width="165px"
                            alt="Go Home"
                        >
                    </a>
                </div>

                <div class="middle">
                    <ul v-if="!showCTA">
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'landing' }"
                                :to="{ name: 'landing' }"
                            >
                                {{ $t('components.navbarMarketing.home') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'app' }"
                                :to="{ name: 'app' }"
                            >
                                {{ $t('components.navbarMarketing.app') }}
                            </router-link>
                        </li>
                        <!--                        <li>-->
                        <!--                            <a-->
                        <!--                                href="https://support.aven.com/hc"-->
                        <!--                                class="nav-item"-->
                        <!--                            >-->
                        <!--                                {{ $t('components.navbarMarketing.support') }}-->
                        <!--                            </a>-->
                        <!--                        </li>-->
                        <li class="has-dropdown">
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'whoWeAre' }"
                                :to="{ name: 'about' }"
                            >
                                {{ $t('components.navbarMarketing.whoWeAre') }}
                            </router-link>
                            <div
                                class="dropdown"
                                aria-labelledby="navbarDropdown"
                            >
                                <router-link :to="{ name: 'about' }">
                                    {{ $t('components.navbarMarketing.about') }}
                                </router-link>
                                <router-link :to="{ name: 'contact' }">
                                    {{ $t('components.navbarMarketing.contact') }}
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="right">
                    <hamburger
                        v-if="!showCTA"
                        :is-expanded="isExpanded"
                        @click="toggleMenu"
                        class="d-block d-lg-none"
                    />

                    <ul
                        v-if="!showCTA"
                        class="d-none d-lg-block"
                    >
                        <li v-if="isLoggedIn">
                            <a
                                class="nav-item"
                                :href="signInPagePath"
                            >
                                {{ $t('components.navbarMarketing.dashboard') }}
                            </a>
                        </li>
                        <li v-else>
                            <a
                                class="nav-item"
                                :href="signInPagePath"
                            >
                                {{ $t('components.navbarMarketing.signin') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div class="mobile-nav">
                <ul>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'landing' }"
                            @click.native="toggleMenu"
                        >
                            {{ $t('components.navbarMarketing.home') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'app' }"
                            @click.native="toggleMenu"
                        >
                            {{ $t('components.navbarMarketing.app') }}
                        </router-link>
                    </li>
                    <!--                    <li>-->
                    <!--                        <a-->
                    <!--                            href="https://support.aven.com/hc"-->
                    <!--                            class="nav-item"-->
                    <!--                        >-->
                    <!--                            {{ $t('components.navbarMarketing.support') }}-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'about' }"
                            @click.native="toggleMenu"
                        >
                            {{ $t('components.navbarMarketing.about') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'contact' }"
                            @click.native="toggleMenu"
                        >
                            {{ $t('components.navbarMarketing.contact') }}
                        </router-link>
                    </li>
                    <li v-if="isLoggedIn">
                        <a
                            class="nav-item"
                            :href="signInPagePath"
                        >
                            {{ $t('components.navbarMarketing.dashboard') }}
                        </a>
                    </li>
                    <li v-else>
                        <a
                            class="nav-item"
                            :href="signInPagePath"
                        >
                            {{ $t('components.navbarMarketing.signin') }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <nav class="nav sticky">
            <div class="container d-flex">
                <div class="left">
                    <a
                        class="brand"
                        href="/"
                    >
                        <img
                            src="../assets/images/components/navbar/aven.svg"
                            class="logo"
                            width="165"
                            alt="Go Home"
                        >
                    </a>
                </div>
                <div class="right">
                    <li>
                        <button
                            class="btn nav-link fw-bold rounded-pill ps-3 pe-3 ms-3"
                            @click="scrollToPhoneInput"
                        >
                            {{ $t('components.navbarMarketing.checkOffers') }}
                        </button>
                    </li>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import Hamburger from './Hamburger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { experimentsMixin } from '../mixins/experimentsMixin'

    export default {
        name: 'NavBar',
        components: {
            hamburger: Hamburger,
        },
        mixins: [experimentsMixin],
        data() {
            return {
                isExpanded: false,
                resourceMenuExpanded: false,
                scrolled: false,
                showCTA: false,
                isLoggedIn: false,
                signInPagePath: process.env.VUE_APP_MY_AVEN_URL,
            }
        },
        created() {
            this.addScrollListener()
            this.isLoggedIn = !!appSessionStorage.getItem(localStorageKey.jwtTokens)
        },
        computed: {
            navContainerClasses: function () {
                return {
                    expanded: this.isExpanded,
                    scrolled: this.scrolled,
                }
            },
            isDarkLogo() {
                return false
            },
        },
        methods: {
            toggleMenu() {
                this.isExpanded ? this.addScrollListener() : this.removeScrollListener()
                this.isExpanded = !this.isExpanded
                this.handleScroll()
            },
            toggleResourceMenu() {
                this.resourceMenuExpanded = !this.resourceMenuExpanded
            },
            handleScroll() {
                this.scrolled = window.scrollY > 800

                const showCta = ['home', 'auto']
                if (showCta.includes(this.$route.name)) {
                    this.showCTA = window.scrollY > 700
                }
            },
            addScrollListener() {
                window.addEventListener('scroll', this.handleScroll)
            },
            removeScrollListener() {
                window.removeEventListener('scroll', this.handleScroll)
            },
            scrollToPhoneInput: async function () {
                const phoneElem = document.getElementById('phone')
                if (!phoneElem) {
                    await this.$router.push({ path: '/' })
                    return
                }
                window.scrollTo(0, 0)
                phoneElem.focus()
                this.isExpanded = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/navbar';
</style>
