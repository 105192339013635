<template>
    <div class="marketing-layout">
        <div
            class="alert marketing-alert alert-warning text-center"
            role="alert"
            v-if="landingWarning"
        >
            <span>{{ landingWarning }}</span>
        </div>
        <navbar />
        <slot />
        <global-footer
            :is-landing="hasLandingFooter"
            :is-payment-calculator="hasPaymentCalculatorFooter"
        />
    </div>
</template>

<script>
    import Footer from '../components/Footer'
    import Navbar from '../components/Navbar'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'

    export default {
        name: 'MarketingLayout',
        components: {
            'global-footer': Footer,
            navbar: Navbar,
        },
        props: {
            hasLandingFooter: {
                type: Boolean,
                default: false,
            },
            hasPaymentCalculatorFooter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                landingWarning: appSessionStorage.getItem(localStorageKey.landingWarning),
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/layouts/marketing';
</style>
