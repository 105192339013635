<template>
    <div class="footer">
        <div class="section">
            <div class="container-lg">
                <div class="row pb-3">
                    <div class="col">
                        <img
                            src="../assets/images/components/navbar/aven.svg"
                            width="165px"
                            alt="Logo"
                        >
                    </div>
                </div>
                <div class="row text-md-start">
                    <div class="col-md-3">
                        <p class="section-header mb-3">
                            Aven
                        </p>
                        <p class="mb-1">
                            <router-link
                                class="text-muted"
                                to="/"
                            >
                                {{ $t('components.navbarMarketing.home') }}
                            </router-link>
                        </p>
                        <p class="mb-1">
                            <router-link
                                class="text-muted"
                                :to="{ name: 'about' }"
                            >
                                {{ $t('components.navbarMarketing.about') }}
                            </router-link>
                        </p>
                        <p class="mb-1">
                            <router-link
                                class="text-muted"
                                :to="{ name: 'careers' }"
                            >
                                {{ $t('components.navbarMarketing.careers') }}
                            </router-link>
                        </p>
                        <p class="mb-1">
                            <router-link
                                :to="{ name: 'app' }"
                                class="text-muted"
                            >
                                {{ $t('components.navbarMarketing.app') }}
                            </router-link>
                        </p>
                    </div>
                    <div class="col-md-3 mt-4 mt-md-0 mb-3">
                        <p class="section-header">
                            Resources
                        </p>
                        <p class="mb-1">
                            <router-link
                                :to="{ name: 'privacy' }"
                                class="text-muted"
                            >
                                {{ $t('components.footer.privacyPolicy') }}
                            </router-link>
                        </p>
                        <p class="mb-1">
                            <a
                                href="/docs/TermsOfUse.pdf"
                                target="_blank"
                                class="text-muted"
                            >{{ $t('components.footer.tos') }}</a>
                        </p>
                        <p class="mb-1">
                            <a
                                href="/docs/WaitlistTerms.pdf"
                                target="_blank"
                                class="text-muted"
                            >{{ $t('components.footer.waitlistTerms') }}</a>
                        </p>
                        <p class="mb-1">
                            <router-link
                                :to="{ path: disclosuresPath }"
                                class="text-muted"
                            >
                                {{ $t('components.footer.disclosures') }}
                            </router-link>
                        </p>
                    </div>
                    <div class="col-md ms-auto d-flex flex-column flex-md-row justify-content-end">
                        <a
                            href="https://apps.apple.com/us/app/aven-card/id1541705487?itsct=apps_box&itscg=30200"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                height="43"
                                src="@/assets/images/components/footer/badge-app-store.png"
                                alt="Download on the App Store"
                                class="mb-2 mb-sm-0"
                            >
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.aven.app&hl=en_US"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                class="ms-md-2"
                                height="43"
                                src="@/assets/images/components/footer/badge-google-play-store.png"
                                alt="Download on Google Play"
                            >
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-6 pb-3 text-sm-left text-muted">
            <div class="container text-md-start">
                <p class="small text-muted">
                    © {{ new Date().getFullYear() }} Aven
                </p>
                <div v-if="isLanding">
                    <p
                        class="small"
                        v-html="footerVerbiage"
                    />
                    <p
                        class="small"
                        v-html="$t('components.footer.cryptoExtended')"
                    />
                    <div>
                        <p class="small">
                            Aven<br>
                            548 Market St #99555<br>
                            San Francisco, California 94104
                        </p>
                        <p class="small">
                            <a
                                href="mailto:support@aven.com"
                                class="text-muted text-underline"
                            >support@aven.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import LanguagePicker from "./LanguagePicker";
    import { marketingPagePaths } from '@/routes/marketingRoutes'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { i18n } from '../utils/i18n'

    export default {
        name: 'Footer',
        props: {
            isLanding: { type: Boolean, default: false },
            disclosuresPath: { type: String, default: marketingPagePaths.DISCLOSURES },
        },
        mixins: [experimentsMixin],
        computed: {
            phoneNumber() {
                return contact_info.phone
            },
            footerVerbiage() {
                // if the user is on the 'join' route, we need to show that legal
                // (unfortunately, it doesn't fall into the same category of experiment or a control group)
                return this.$route.name === 'join' ? i18n.t(`components.footer.legal`) : i18n.t(`experiments.${this.heraclesParameter.EXPERIMENT_NAME}.footer`)
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/components/footer.scss';
</style>
